<template>
    <div class="power-player-list" >


        <el-dialog
                title="EDIT VIDEO"
                center
                :width="windowWidth > 800 ? `40%` : `95%`"
                class="el-dialog--huge dialog_edit dialog_border_radius"
                :visible.sync="editPlayerDilogue"
                :modalAppendToBody="false"
        >
            <div class="relative mb-3 ">
                <el-input v-model="videoToEdit.title" placeholder="VIDEO TITLE"></el-input>
                <span v-if="validateVideo.title">{{validateVideo.message}}</span>

                <div style="margin-top: 30px;">
                    <el-input placeholder="Please input" v-model="videoToEdit.path" class="input-with-select">
                        <el-select v-model="videoToEdit.power_player_type" slot="prepend"
                                   :placeholder="videoToEdit.power_player_type" :style="{'width': 150+'px'}">
                            <el-option label="YOUTUBE" value="YOUTUBE"></el-option>
                            <el-option label="MP4" value="MP4/WEBM"></el-option>
                            <el-option label="WISTIA" value="WISTIA"></el-option>
                            <el-option label="VIMEO" value="VIMEO"></el-option>
                            <el-option label="M3U8" value="M3U8/MPD"></el-option>
                            <el-option label="LIVE" value="LIVE STREAMING"></el-option>
                        </el-select>
                        <!--                                        <el-button slot="append" icon="el-icon-search"></el-button>-->
                    </el-input>
                    <span v-if="validateVideo.path">{{validateVideo.message}}</span>
                </div>
                <el-col class="row-space-3 mt-2">
                    <el-checkbox v-model="videoToEdit.youtube_control">
                        <span class="text-lightest" style="color: #21455E">Display YouTube logo for compliance</span>
                    </el-checkbox>
                </el-col>

            </div>

            <div slot="footer" class="dialog-footer">
                <el-button class="btn btn-primary add-power-player-button" @click="powerPlayerVideoEdit">UPDATE
                </el-button>
            </div>
        </el-dialog>

        <!-- <el-dialog
                title="ARE YOU SURE?"
                center
                :width="windowWidth > 1024 ? `40%` : '95%'"
                class="el-dialog--huge dialog_border_radius"
                :visible.sync="showDeletePopup"
                :modalAppendToBody="false"
        >
            <div class="el-dialog__body" :style='{"padding": windowWidth <= 1024 ? "0px !important" : ""}'>
                <div style="display:flex;flex-direction:column">
          <span style="margin-top:10px">
            You are about to Delete this Video.Deleted videos are removed from your account
            and they will become unavailable wherever they are embeded
          </span>
                </div>
                <div class="col-md-12" style="margin-top:20px">
                    <div class="col-md-4" style="display:flex;flex-direction:column">
                        <label>VIDEO</label>
                        <div style="word-break: break-word">{{videoToEdit.title}}</div>
                    </div>
                    <div class="col-md-4" style="display:flex;flex-direction:column">
                        <label>IMPRESSIONS</label>
                        <span>{{videoToEdit.impressions}}</span>
                    </div>
                    <div class="col-md-4" style="display:flex;flex-direction:column">
                        <label>VIEWS</label>
                        <span>{{videoToEdit.views}}</span>
                    </div>
                </div>
                <div style="margin-top:100px">
                    <label>
                        Type
                        <b style="color:red">DELETE</b> to confirm
                    </label>
                    <div class="row-space-top-1 el-input">
                        <input
                                type="text"
                                autocomplete="off"
                                v-model="typeConfirmDlete"
                                class="el-input__inner custom_input_new_style"
                        />
                    </div>

                    <span style="color:red" v-if="isTypedDeleteError">
            You must type
            <b style="color:red">DELETE</b> to confirm
          </span>
                </div>
            </div>

            <span slot="footer" class="dialog-footer">
        <el-button @click="showDeletePopup = false" :loading="loadingDelete" type="gray" round>Cancel</el-button>
        <el-button type="danger" :loading="loadingDelete" @click="deleteVideoConfirm" round>Delete</el-button>
      </span>
        </el-dialog> -->


        <el-dialog
                title="DUPLICATE VIDEO"
                center
                :width="windowWidth > 1024 ? `40%` : '95%'"
                class="el-dialog--huge dialog_border_radius duplicate-power-player"
                :visible.sync="showDuplicatePopup"
                :modalAppendToBody="false"
        >
            <div :style='{"padding": windowWidth <= 1024 ? "0px !important" : ""}'>
                <div style="display:flex;flex-direction:column">
                    <span style="margin-top:10px">Create a duplicate copy of this power player</span>
                </div>
                <div style="margin-top:20px">
                    <label>Title</label>
                    <div class="row-space-top-1 el-input">
                        <input
                                type="text"
                                autocomplete="off"
                                v-model="duplicateVideoTitle"
                                class="el-input__inner custom_input_new_style"
                        />
                    </div>

                    <span style="color:red" v-if="duplicateVideoTitle==''">This field is required</span>
                </div>
            </div>

            <span slot="footer" class="dialog-footer">
        <el-button @click="showDuplicatePopup = false" type="gray">Cancel</el-button>
        <el-button type="primary" @click="duplicateVideo">Duplicate</el-button>
      </span>
        </el-dialog>

        <div @mouseover="showChecker = true" @mouseout="singleChecks.length < 1 ? showChecker = false : ''">
            <div class="show-on-bulk-select">
                <div style="padding-top:10px">
                    <el-checkbox v-if="bulkOpt" v-model="checkAll" @input="TickAll"></el-checkbox>
                </div>
                <div>
                    <div v-if="singleChecks.length > 0">
                        <span class="bulk-selected-count">{{ singleChecks.length }} Videos Selected, BULK ACTIONS:</span>
                        <span class="bulk-ctas-cover">
                            <button @click="type = 'Videos'; moveDialogVisible = true" class="bulk-ctas bulk-cta-move">MOVE</button>
                            <button @click="deleteType = 'Videos'; iDeleteDialogVisible = true;" class="bulk-ctas bulk-cta-delete">DELETE</button>
                        </span>
                    </div>
                    <div v-else style="padding:20px; ">{{  }}</div>
                </div>
            </div>
            <div v-for="(video, index) in powerVideos" :key="video.id" style="display:flex">
                <el-checkbox-group v-if="showChecker || singleChecks.length > 0" v-model="singleChecks" @change="handleCheckedCitiesChange">
                    <div><el-checkbox class="video-checkr" :label="video.id">{{  }}</el-checkbox></div>
                </el-checkbox-group>
                <div v-else class="checker-space" style="padding: 0 21px;">{{  }}</div>
                <div class="player_video__body">
                    <div class="list-power-video">
                        <div class="power-player-id inner-div" @click="redirectTo(video.video_id)">
                            <span :style="{'background-color': backgroundColor(video.power_player_type)}">#{{index + 1}}</span>
                        </div>
                        <div class="power-player-detail inner-div" @click="redirectTo(video.video_id)">
                            <div class="item__title">{{video.title}}</div>
                            <div class="item__path"> {{video.path}}</div>
                        </div>
                        <div class="power-player-impression inner-div" @click="redirectTo(video.video_id)">
                            <div>
                                <div class="item__title">{{video.impressions}}</div>
                                <div class="item__path"> Impresions</div>
                                <!--                        <span>{{video.impressions}}</span><br>-->
                                <!--                        <span class="tag_title">Impresions</span>-->
                            </div>
                        </div>
                        <div class="power-player-views inner-div" @click="redirectTo(video.video_id)">
                            <div class="item__title">{{video.views}}</div>
                            <div class="item__path"> Views</div>
                            <!--                    <span> {{video.views}}</span><br>-->
                            <!--                    <span class="tag_title">Views</span>-->
                        </div>
                        <div class="power-player-actions inner-div">
                            <div>
                                <el-dropdown class="m-left-2" trigger="click" :placement="windowWidth > 550 ? 'bottom' : 'left'" @command="handleCommand" @visible-change="videoble($event, video)">
                                    <div class="el-dropdown-link btn btn-circle">
                                        <i class="el-icon-more"></i>
                                    </div>
                                    <el-dropdown-menu slot="dropdown" class="custom_dropdown dropdown_bottom_200">
                                        <el-dropdown-item command="share">Share & Embed</el-dropdown-item>
                                        <el-dropdown-item command="copy-link">Copy Player Link</el-dropdown-item>
                                        <el-dropdown-item command="edit" divided>Edit Title & Source</el-dropdown-item>
        <!--                                <el-dropdown-item command="analytics">Video Analytics</el-dropdown-item>-->
                                        <el-dropdown-item command="move" divided><div  @click.prevent="addVidId(video.id)">Move</div></el-dropdown-item>
                                        <el-dropdown-item command="duplicate">Duplicate</el-dropdown-item>
                                        <el-dropdown-item command="delete" class="delete" style="color:red">
                                            <div  @click.prevent="boutDel[0] = video.id">Delete</div>
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </div>
                        </div>
                        
                        <!-- MOVE VIDEO DIALOG -->
                        <el-dialog
                        title="MOVE VIDEO TO PROJECT"
                        :visible.sync="moveDialogVisible"
                        :width="windowWidth > 1024 ? `40%` : '95%'"
                        center
                        @close="closeMoveDialog"
                        >
                            <div><span>Move this Video to another folder.</span></div>
                            
                            <!-- MOVE VIDEO DROPDOWN     -->
                            <div>
                                <div v-if="selectedProject" style="margin-top: 10px;">
                                    <span>Move to Project</span>
                                </div>
                                <el-dropdown class="move-dropdown" trigger="click" @command="moveHandleCommand" >
                                    <!-- Select Handle -->
                                    <div v-if="selectedProject" class="result-item">
                                        <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="40" viewBox="0 0 81.5 62.476">
                                                <g id="folder5" transform="translate(-17 -47.619)">
                                                    <g id="Group_16805" data-name="Group 16805" transform="translate(17 47.619)">
                                                    <path id="Path_9870" data-name="Path 9870" d="M74.309,55.817h-33.1l-6.592-7.838a1,1,0,0,0-.8-.36H7.191A7.263,7.263,0,0,0,0,54.89v47.941a7.263,7.263,0,0,0,7.191,7.263H74.309a7.263,7.263,0,0,0,7.191-7.263V63.08A7.263,7.263,0,0,0,74.309,55.817Z" 
                                                    transform="translate(0 -47.619)" fill="#282732"/>
                                                    </g>
                                                </g>
                                            </svg>
                                        </div>
                                        <div class="filtered-result-item-txt">
                                            <div>{{ selectedProject.title }}</div>
                                            <div>{{ selectedProject.project_videos_count }} videos</div>
                                        </div>
                                    </div>
                                    <div v-else class="sel-dd-link el-dropdown-link" >
                                        <span class="sel-dd-link">Select Folder </span> 
                                        <i class="el-icon-arrow-down el-icon--right sel-fold"></i>
                                    </div>
                                    <el-dropdown-menu class="m-drop" slot="dropdown" style="width:37%">
                                        <!-- Search input -->
                                        <div class="i-dropdown-search-outercover">
                                            <div class="i-dropdown-search-cover">
                                                <span class="i-search-icon"><i class="el-icon-search"></i></span>
                                                <input class="i-dropdown-search" v-model="moveSearchText" type="search" name="searchFolder" placeholder="Search Projects">
                                            </div>
                                        </div>
                                        <!-- Drowdown Lists -->
                                        <div class="result-lists">
                                            <el-dropdown-item v-for="filteredProject in filteredProjects" :key="filteredProject.id" 
                                            class="result-item-cover" :command="filteredProject">
                                                <div class="result-item" v-if="pageId != filteredProject.id" >
                                                    <div>
                                                        <span class="i-dropdown-search-results-check-icon">
                                                            <i v-if="selectedProject && filteredProject.id  == selectedProject.id" class="el-icon-check"></i>
                                                            <i v-else>&nbsp; &nbsp; &nbsp;</i>
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="40" viewBox="0 0 81.5 62.476">
                                                            <g id="folder5" transform="translate(-17 -47.619)">
                                                                <g id="Group_16805" data-name="Group 16805" transform="translate(17 47.619)">
                                                                <path id="Path_9870" data-name="Path 9870" d="M74.309,55.817h-33.1l-6.592-7.838a1,1,0,0,0-.8-.36H7.191A7.263,7.263,0,0,0,0,54.89v47.941a7.263,7.263,0,0,0,7.191,7.263H74.309a7.263,7.263,0,0,0,7.191-7.263V63.08A7.263,7.263,0,0,0,74.309,55.817Z" 
                                                                transform="translate(0 -47.619)" fill="#282732"/>
                                                                </g>
                                                            </g>
                                                        </svg>
                                                    </div>
                                                    <div class="result-item-txt">
                                                        <span>{{ filteredProject.title }}</span>
                                                        <span class="result-item-txt-video-count">{{ filteredProject.project_videos_count }} videos</span>
                                                    </div>
                                                </div>
                                            </el-dropdown-item>
                                        </div>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </div>
                            <div v-if="selectedProject" style="text-align:center !important; margin-top:25px; ">
                                <span v-if="moveLoading">
                                    <span v-loading="moveLoading" style="position:relative"></span>
                                </span>
                                <span v-else slot="footer" class="dialog-footer">
                                    <el-button v-if="selectedProject" @click="moveDialogVisible = false" class="i-dialog-btn i-dialog-btn-move-cancel">Cancel</el-button>
                                    <el-button v-if="selectedProject" type="primary" @click="moveVideo(type)" class="i-dialog-btn i-dialog-btn-move-confirm">Confirm</el-button>
                                </span>
                            </div>
                        </el-dialog>

                        
                       </div>
                </div>
            </div>
        </div>
         <!-- Delete Modal -->
            <delete-component :iDeleteComDialogVisible="iDeleteDialogVisible" :idToDel="boutDeleteIds" :inReq="deleteType" 
            @reloadDelDialog="loadDeleteDialog($event)" @bootProjects="reloadVideos" @clearProjectIds="boutDel = []; 
            movingVideosIds = []" :delUrl="this.delUrl"></delete-component>
                    
        <embed-modal ref="shareModal" :video="videoToEdit" v-if='embedModal'
        @trigger='embedModal = false'></embed-modal>





                    

    </div>
</template>

<script>

    import EmbedModal
        from '../../../components/Project/ChildPages/ProjectExperimentationPages/CommonComponents/EmbedModal';

    import DeleteComponent from '../../Project/AllProjects/Components/DeleteComponent.vue'
    import { debounce } from 'lodash'
    export default {
        name: 'List',
        props: ['powerVideos'],
        components: {EmbedModal, DeleteComponent},
        data: function () {
            return {
                delUrl: '/powerPlayerVideos/del-vids',
                bulkOpt: false,
                type: '',
                deleteType: '',
                moveLoading : false,  
                pageId : null,   
                selectedProject: null,
                movingVideosIds: [],
                moveDialogVisible: false,
                moveSearchText: '',
                filteredProjects: [],
                singleChecks: [],
                projects: [],
                checkAll: false,
                showChecker : false, 
                iDeleteDialogVisible: false,
                boutDel: [],
                videoToEdit: {
                    mine: true,
                    title: '',
                    power_player_type: 'YOUTUBE',
                    path: '',
                    youtube_control:true,
                },
                editPlayerDilogue: false,
                showDeletePopup: false,
                validateVideo: {
                    title: false,
                    power_player_type: false,
                    path: false,
                    message: ''
                },
                typeConfirmDlete: "",
                loadingDelete: false,
                isTypedDeleteError: false,
                showDuplicatePopup: false,
                duplicateVideoTitle: '',
                windowWidth: null,
                embedModal: false,
            }
        },
        created: function () {
            this.pageId =  this.$route.params.id;
            this.loadAllProjects();
        },
        mounted: function () {
            window.addEventListener('resize', this.onResize);
            this.onResize();
        },
        computed: {
            showBulkOpt() {
                this.showChecker || this.singleChecks.length > 0 ? true : false
            },
            boutDeleteIds() {
                return this.deleteType == 'Video' ? this.boutDel : this.singleChecks;
            },
        },
        watch: {
            moveSearchText() {
                this.filterMoveSearch();
            },
            showChecker: debounce(function(value) {
                this.bulkOpt = value
                }, 5
            ),
        },
        methods: {
            TickAll() {
                this.singleChecks = this.checkAll ? this.powerVideos.map(vid => vid.id) : [];
            },
            handleCheckedCitiesChange(val) {
                // console.log(val);
                this.singleChecks.length < 1 ? this.checkAll = false : '';
            },
            delNotification(msg, title, type) {
                this.$notify({
                    type: type,
                    title: title,
                    message: msg,
                    position: 'bottom-left',
                });
            },
            async moveVideo(type) {
                this.moveLoading = true;
                let formData = {
                    project: this.selectedProject.id,
                    videos: type == 'Video' ? this.movingVideosIds : this.singleChecks,
                    req_type: type,
                }
                this.axios.post('/projects/mv-vids/', formData)
                .then(response => {
                    this.filteredProjects = this.projects
                    let msg = response.data.message;
                    if(response.status === 200) {
                        // hide the modal
                        this.moveDialogVisible = false;
                        this.selectedProject= null;
                        this.movingVideosIds= [];
                        this.moveLoading = false;
                        this.singleChecks = [];
                        this.$emit('getPowerVideos');
                        // show success notification
                        this.delNotification(msg, 'Moved', 'success');
                        this.loadAllProjects();
                        
                    } else {
                        this.moveLoading = false;
                        // show error notification
                        this.delNotification(msg, 'Error', 'error');
                    }
                })
                .catch(error => {
                console.log(error.response)
                    this.moveLoading = false;
                    this.$message.error(error.response.status == 403 ? error.response.data.message : 'Error! Something went wrong');
                    if(error.response.status === 401) {
                        this.$store.state.userData = null;
                        this.$store.dispatch("destroyToken").then(response => {
                            window.location.replace("/login/1");
                        });
                    } 
                });
            },
            reloadVideos() {
                this.$emit('getPowerVideos');
            },
            loadAllProjects() {
                let userId = this.$store.getters.userInfo ? this.$store.getters.userInfo.id : false;
                this.axios.get('/projects', { params: { filter: this.filter, user_id: userId, v2: true } })
                .then(response => {
                    this.projects = this.filteredProjects = response.data;
                });
            },
            addVidId(id) {
                this.movingVideosIds[0] = id;
            },
            clearDelDialoogInput() {
                this.typeDeleteError = '';
                this.typeDelete = '';
            },
            moveHandleCommand(command) {
                this.selectedProject = command;
                this.moveSearchText = '';
            },
            filterMoveSearch() {
                //  If there is search text in the search input, filter the projects with the input text
                if(this.moveSearchText.length > 0) {
                    let filteredResults = this.projects.filter(project => {
                        return project.title.toLowerCase().indexOf(this.moveSearchText.toLowerCase()) > -1
                    });
                    this.filteredProjects = filteredResults;
                } else {
                    this.filteredProjects = this.projects;
                }
            },
            loadDeleteDialog(res) {
                if(res == 'false') {
                    this.iDeleteDialogVisible = false
                } else if(res == 'true'){
                    this.iDeleteDialogVisible = true;
                }
            },
            closeMoveDialog(){
                // When the move dialog closes, do these
                //  Filtered projects should be all the projects
                this.filteredProjects = this.projects;
                //  Empty search field
                this.moveSearchText = '';
                //  there should not be any folder selected to move video(s) to
                this.selectedProject = null;
            },
            redirectTo(video_id) {
                this.$router.push('/power-player/edit/' + video_id);
            },
            backgroundColor(type) {
                var color = ''
                if (type == 'YOUTUBE') {
                    color = '#af0a0a';
                } else if (type == 'VIMEO') {
                    color = '#40b6da';
                } else if (type == 'WISTIA') {
                    color = 'rgb(10 68 177)';
                } else if (type == 'MP4') {
                    color = '#25a95af0';
                } else if (type == 'M3U8') {
                    color = '#a92574';
                } else if (type == 'LIVE') {
                    color = '#e8c207';
                }
                return color

            },
            onResize() {
                this.windowWidth = window.innerWidth;
                if (this.windowWidth <= 1024) return false;
                return true;
            },
            videoble(event, video) {
                if (event) {
                    this.videoToEdit = video
                }
            },
            handleCommand(command) {

                if (command == "copy-link") {
                    console.log(this.videoToEdit);
                    var el = document.createElement("textarea");
                    el.value = this.videoToEdit.embed_url;
                    document.body.appendChild(el);
                    el.select();
                    document.execCommand("copy");
                    document.body.removeChild(el);
                }

                if (command == "share") {
                    this.embedModal = true;
                }

                if (command == "edit") {
                    this.editPlayerDilogue = true;
                }
                if (command == "move") {
                    this.type = 'Video';
                    this.moveDialogVisible = true;
                }
                if (command == "delete") {
                    // this.showDeletePopup = true;
                    this.iDeleteDialogVisible = true;
                    this.deleteType = 'Video';
                }
                if (command == "analytics") {
                    window.location.href = "/analytics?video=" + this.videoToEdit.id;
                }
                if (command == "duplicate") {
                    this.showDuplicatePopup = true;
                    this.duplicateVideoTitle = this.videoToEdit.title + " 2";
                }

            },
            powerPlayerVideoEdit() {
                this.validateVideo.title = false;
                this.validateVideo.message = '';
                this.validateVideo.path = false;

                if (this.videoToEdit.title == '') {
                    this.validateVideo.title = true;
                    this.validateVideo.message = 'Please enter video title';
                    return false;
                } else {
                    this.validateVideo.title = false;
                    this.validateVideo.message = '';
                }
                if (this.videoToEdit.path == '') {
                    this.validateVideo.path = true;
                    this.validateVideo.message = 'Please enter source URL';
                    return false;
                } else {


                    if (this.videoToEdit.power_player_type == 'YOUTUBE') {
                        this.validateVideo.path = true;
                        this.validateVideo.message = 'Please enter valid source URL';
                        if (!this.videoToEdit.path.includes('www.youtube.com')) {
                            return false;
                        }
                    }
                    if (this.videoToEdit.power_player_type == 'WISTIA') {
                        this.validateVideo.path = true;
                        this.validateVideo.message = 'Please enter valid source URL';
                        if (!this.videoToEdit.path.includes('wistia.com')) {
                            return false;
                        }
                    }
                    if (this.videoToEdit.power_player_type == 'VIMEO') {
                        this.validateVideo.path = true;
                        this.validateVideo.message = 'Please enter valid source URL';
                        if (!this.videoToEdit.path.includes('vimeo.com')) {
                            return false;
                        }
                    }

                    if (this.videoToEdit.power_player_type == 'MP4') {

                        var re = /(?:\.([^.]+))?$/;

                        var ext = re.exec(this.videoToEdit.path)[1];

                        if (typeof ext == 'undefined' || ext !== "mp4") {
                            this.validateVideo.path = true;
                            this.validateVideo.message = 'Please enter valid source URL';
                            if (!this.videoToEdit.path.includes('vimeo.com')) {
                                return false;
                            }
                        }
                    }
                    if (this.videoToEdit.power_player_type == 'M3U8') {

                        var re = /(?:\.([^.]+))?$/;

                        var ext = re.exec(this.videoToEdit.path)[1];

                        if (typeof ext == 'undefined' || ext !== "m3u8") {
                            this.validateVideo.path = true;
                            this.validateVideo.message = 'Please enter valid source URL';
                            if (!this.videoToEdit.path.includes('vimeo.com')) {
                                return false;
                            }
                        }
                    }


                    this.validateVideo.path = false;
                    this.validateVideo.message = '';
                }

                this.axios.post(`/powerPlayerVideos/edit/${this.videoToEdit.id}`, this.videoToEdit)
                    .then(response => {

                        this.editPlayerDilogue = false;
                        this.$emit('getPowerVideos');


                    });
            },
            deleteVideoConfirm: function () {
                if (this.typeConfirmDlete == "" || this.typeConfirmDlete != "DELETE") {
                    this.isTypedDeleteError = true;
                } else {
                    this.isTypedDeleteError = false;
                    this.loadingDelete = true;
                    let $this = this;

                    this.axios({
                        method: "post",
                        url: `powerPlayerVideos/delete/${this.videoToEdit.id}`,

                    })
                        .then(response => {
                            this.loadingDelete = false;
                            this.typeConfirmDlete= ''
                            this.showDeletePopup = false;
                            this.$emit('getPowerVideos');
                            // if (
                            //     typeof response.data.success !== typeof undefined &&
                            //     response.data.success === true
                            // ) {
                            //     $this.$router.push({
                            //         name: "ProjectView",
                            //         params: {
                            //             id: $this.video.project.project_id
                            //         }
                            //     });
                            // }
                        })
                        ["catch"](error => {
                    });
                }
            },
            duplicateVideo() {

                var data = {
                    video_id: this.videoToEdit.video_id,
                    video_title: this.duplicateVideoTitle,
                }
                this.axios.post(`/powerPlayerVideos/duplicate`, data)
                    .then(response => {

                        this.showDuplicatePopup = false;
                        this.$emit('getPowerVideos');


                    });
            },
        },
    }
</script>

<style lang="less">
    .m-drop {
        box-shadow: 0px 3px 6px #00000014 !important;
        border: 1px solid #0BACDB !important;
        border-radius: 5px;
        opacity: 1;
    }
    @media screen and (max-width:1024px) {
        .m-drop {
            width: 90% !important;
            left: 5% !important;
        }
        
    }
    .i-dropdown-search-outercover {
        padding: 20px;
    }
    .i-dropdown-search-cover {
        padding: 2px 10px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #99D6E9;
        border-radius: 20px;
        opacity: 1;
    }
    .i-dropdown-search {
        border: none !important;
        outline: none !important;
        width: 90%;
        opacity: .5;
    }
    .i-dropdown-search:focus {
        opacity: .8;
    }
    .i-dropdown-search-results-check-icon {
        font-size: 10px;
        color: #5e5959;
        margin-right: 10px;
    }
    .move-dropdown {
        display:block; 
        width:100%;  
        border: 1px solid #00ACDC;
        border-radius: 4px;
        opacity: 1;    
        padding: 5px 15px
    }
    .sel-fold {
        float:right;    
        margin-top: 4px;
    }
    .sel-dd-link {
        font-size: 15px !important;
    }
    .sel-dd-link::hover {
        cursor: pointer;
    }
    .i-search-icon{
        color:#00ACDC; 
        font-size:16px
    }
    .result-lists {
        max-height: 300px;
        overflow-y: scroll;
    }
    .result-item-cover:hover {
        border-bottom: 1px solid #e0eff3b3;
        margin-bottom: 20px;
    }
    .result-item {
        display:flex
    }
    .result-item-txt {
        margin-top: -3px; 
        font-size:13px; 
        padding: 0 15px;
    }
    .filtered-result-item-txt {
        font-size: 13px;
        padding: 3px 15px;
    }
    .result-item-txt-video-count {
        display:block; 
        margin-top: -20px;
    }
    .i-dialog-btn {
        border-radius: 18px;
        padding: 9px 15px;
        position: relative;
        transition: all .5s;
    }
    .i-dialog-btn:hover {
        top: -2px;
        transform: scale(1.03);
    }
    .i-dialog-btn-move-cancel {
        background: #637C8E 0% 0% no-repeat padding-box;
        color:white;
        border: none !important;
    }
    .i-dialog-btn-move-cancel:hover {
        color:white !important;
        border: none !important;
    }
    .video-checkr {
        top: 40px;
        margin-right: 20px;
    }
    .show-on-bulk-select {
        display: flex;
        padding-bottom: 20px;
    }
    .bulk-ctas-cover {
        margin-left: 40px;
    }
    .bulk-ctas {
        border-radius: 5px;
        opacity: 1;
        text-align: center;
        font-size: 16px;
        letter-spacing: 0px;
        color: #ffffff !important;
        text-transform: capitalize;
        border: none;
        padding: 5px 10px;
        margin: 0 10px;
    }
    .bulk-cta-move {
        background: #6386BE 0% 0% no-repeat padding-box;
    }
    .bulk-cta-delete {
        background: #EE5951 0% 0% no-repeat padding-box;
    }
    .bulk-selected-count {
        text-align: left;
        font: normal normal normal 14px/26px Helvetica Neue;
        letter-spacing: 0px;
        color: #FFFFFF;
        margin-left: 20px;
    }

    // SCROLLBAR
    /* width */
    .result-lists::-webkit-scrollbar {
        width: 8px;
    }

    /* Track */
    .result-lists::-webkit-scrollbar-track {
        border: 1px solid #eaf8fc;
        border-radius: 15px;
    }
    
    /* Handle */
    .result-lists::-webkit-scrollbar-thumb {
        background: #C9EDF8;
        border-radius: 15px;
    }

    /* Handle on hover */
    .result-lists::-webkit-scrollbar-thumb:hover {
        background: #00ACDC; 
    }



    .duplicate-power-player{
        .el-dialog__body{}
    }

    .el-dialog--center .el-dialog__header {
        padding: 25px 40px;
        text-align: center;
        border-bottom: 1px solid #e4e8f1;
    }

    .el-dialog__title {
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: normal;
        font-size: 15px;
    }

    .power-player-list {

        .tag_title {
            font-size: 15px;
        }

        .content__col {
            flex-grow: 1;
            flex-shrink: 1;
            min-width: 0;
            padding: 0 8px;

            &.content__col--image {
                position: relative;
                flex-grow: 0;
                flex-basis: 136px;
                flex-shrink: 0;

                img {
                    width: 120px;
                    height: 67px;
                    object-fit: cover;
                }
            }

            &.content__col--title {
                flex-basis: 400px;
                min-width: 150px;
            }

            &.content__col--view {
                flex-basis: 100px;
                min-width: 100px;
            }

            &.content__col--comment {
                flex-basis: 150px;
                min-width: 150px;
            }

            &.content__col--size {
                flex-basis: 100px;
                min-width: 100px;
            }

            &.content__col--last-modified {
                flex-basis: 150px;
                min-width: 150px;
            }
        }

        .player_video__body {
            background-color: white;
            /* padding: 30p x 30px 30px; */
            box-shadow: 0px 2px 6px 0px #00000026;
            border-radius: 5px;
            margin-bottom: 30px;
            height: 100px;
            width: 100%;
            cursor: pointer;
            @media(max-width:520px){
                height: 80px;
            }

            .list-power-video {
                display: flex;

                .inner-div {
                    flex-wrap: wrap;

                }

                .power-player-id, .power-player-actions {
                    flex: 0 0 10%;
                }

                .power-player-actions {
                    line-height: 70px;
                    text-align: center;
                    .btn-circle {
                        border: 0;
                        background-color: transparent;
                        font-size: 16px;
                        border-radius: 30px;
                        color: #0BACDB;
                        padding: 8px 11px !important;
                        border: 1px solid #0BACDB !important;


                        &:hover {
                            background-color: #F7FCFD;
                        }
                    }

                    .el-dropdown-link {
                        color: #0BACDB;
                    }
                }

                .power-player-detail {
                    flex: 0 0 50%;
                    padding: 25px 15px;
                    @media(max-width:650px){
                        flex: 0 0 40%;
                    }
                    @media(max-width:510px){
                        flex: inherit;
                        padding: 15px 5px 15px 10px;
                    }
                    @media(max-width: 450px){
                        flex-grow: 1;
                    }

                    .item__title {
                        font-size: 14px;
                        margin-bottom: 9px;
                        line-height: 27px;
                        font-weight: 500;
                        font-size: 22px;
                        color: #2e455ebd;
                        font-weight: 500;
                        display: inline-block;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        max-width: 350px;
                        display: block;


                        &:hover {
                        }
                    }

                    .item__path {
                        font-size: 13px;
                        color: #2c96b6;
                        font-style: italic;
                        max-width: 350px;
                        display: inline-block;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        @media(max-width:450px){
                            max-width: 160px !important;
                        }
                        @media(max-width:400px){
                            max-width: 130px !important;
                        }
                        @media(max-width:359px){
                            max-width: 100px !important;
                        }

                    }
                }

                .power-player-impression, .power-player-views {
                    flex: 0 0 15%;
                    padding: 25px 15px;
                    /*line-height: 100px;*/
                    /*font-size: 22px;*/
                    text-align: center;
                    @media(max-width:650px){
                        flex: 0 0 17%;
                    }
                    @media(max-width:570px){
                        flex: 0 0 12%;
                        padding: 25px 3px;
                    }
                    @media(max-width:520px){
                        flex: 0 0 12%;
                        padding: 15px 3px;
                    }

                    .item__title {
                        font-size: 15px;
                        margin-bottom: 9px;
                        line-height: 27px;
                        font-weight: 500;
                        font-size: 22px;
                        color: #000000bd;
                        font-weight: 500;


                        &:hover {
                        }
                    }

                    .item__path {
                        font-size: 13px;
                        /*color: #2c96b6;*/
                        /*font-style: italic;*/
                        max-width: 350px;
                        display: inline-block;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;

                    }
                }

                /*.power-player-views {*/
                /*    flex: 0 0 15%;*/
                /*    line-height: 100px;*/
                /*    font-size: 22px;*/
                /*    text-align: center;*/
                /*}*/

                .power-player-id {
                    span {
                        text-align: center;
                        display: block;
                        /*height: 100%;*/
                        font-size: 22px;
                        line-height: 100px;
                        font-weight: bold;
                        color: #fff;
                        background-color: #af0a0a;
                        border-top-left-radius: 5px;
                        border-bottom-left-radius: 5px;
                        @media(max-width:510px){
                            line-height: 80px;
                        }
                    }
                }
            }
        }

        .el-dropdown{
            margin-top: 15px;
            @media(max-width:520px){
                margin-top:5px;
                margin-right: 10px;
            }
        }
        .el-dropdown-link{
            font-size: 10px !important;
        }
        /*<!--.el-dropdown-menu:before {-->*/
        /*<!--    content: '';-->*/
        /*<!--    display: block;-->*/
        /*<!--    position: absolute;-->*/
        /*<!--    width: 14px;-->*/
        /*<!--    height: 14px;-->*/
        /*<!--    box-sizing: border-box;-->*/
        /*<!--    transform: rotate(45deg);-->*/
        /*<!--    border: 1px solid var(&#45;&#45;color-blue);-->*/
        /*<!--    background-color: white;-->*/
        /*<!--    border-radius: 3px;-->*/
        /*<!--}-->*/

        /*<!--.el-dropdown-link {-->*/
        /*<!--    text-align: center;-->*/
        /*<!--    width: 40px;-->*/
        /*<!--    height: 40px;-->*/
        /*<!--    border: 2px solid #469edb;-->*/
        /*<!--    border-radius: 100px;-->*/
        /*<!--    background: url(../../../assets/img/three_points_h.svg) center center no-repeat, url(../../../assets/img/three_points_h.svg) -9999px no-repeat !important;-->*/
        /*<!--    background-size: 15px !important;-->*/
        /*<!--    display: inline-block;-->*/
        /*<!--    margin-top: 30px;-->*/
        /*<!--    cursor: pointer;-->*/
        /*<!--}-->*/

        .custom_dropdown.dropdown_bottom_200 {
            width: 200px !important;
        }

        .custom_dropdown.dropdown_bottom_200:before {
            top: -7px;
            left: 93px;
            border-bottom-color: transparent;
            border-right-color: transparent;
        }

        .custom_dropdown:before {
            content: "";
            display: block;
            position: absolute;
            width: 14px;
            height: 14px;
            box-sizing: border-box;
            transform: rotate(45deg);
            border: 1px solid var(--color-blue);
            background-color: #fff;
            border-radius: 3px;
        }

        .custom_dropdown {
            list-style: none;
            border-radius: 6px;
            background: #fff;
            border: 1px solid #0bacdb;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08);
            margin-top: 0 !important;
            padding: 5px 0;
        }

        .title {
            display: flex;
            align-items: flex-end;

            span {
                font-weight: 700;
                font-size: 24px;
                letter-spacing: 1px;
                margin-bottom: 0;
                border: 1px solid transparent;
                padding: 10px 0 0 10px;
                margin-left: -5px;
                padding-left: 5px;
                border-radius: 4px;
                -webkit-border-radius: 4px;
                -moz-border-radius: 4px;
                line-height: 1.2em;
                color: var(--color-content);
                white-space: nowrap;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis !important;
            }

            .editIcon {
                cursor: pointer;
                padding-left: 15px;
            }

            // &:hover{
            //     border: 1px solid #DBE3E6;
            // }
        }

        /*input {*/
        /*    font-size: 24px;*/
        /*    font-weight: 700;*/
        /*    padding: 10px 5px !important;*/
        /*    margin: 0;*/
        /*    letter-spacing: 1px;*/
        /*    margin-left: -5px;*/
        /*    height: 50px !important;*/
        /*}*/

        .dropdown-container {
        }

        .meta-info {
            font-size: 0.9em;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 100%;
        }
    }

</style>
