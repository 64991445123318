<template>
    <div class="power-player-page">

        <div class="power-player-dashboard">
            <div class="hero__content">
                 <div class="d-flex align-items-center" style="    margin-bottom: 15px;">
                    <router-link class="link__projects" :to="{ path: '/projects' }">Projects</router-link>
                    <div class="header__icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="5.395" height="9.709" viewBox="0 0 5.395 9.709">
                            <g id="Arrow_-_Dropdown_-_Direction" data-name="Arrow - Dropdown - Direction"
                               transform="translate(0.232 11.021) rotate(-90)">
                                <path id="Path_2500" data-name="Path 2500"
                                      d="M15.582,33.5a.328.328,0,0,0-.463,0l-4.075,4.083L6.959,33.5a.328.328,0,1,0-.463.463L10.8,38.267a.32.32,0,0,0,.232.1.334.334,0,0,0,.232-.1l4.307-4.307A.321.321,0,0,0,15.582,33.5Z"
                                      transform="translate(-4.856 -33.4)" fill="var(--textThemeColor)" stroke="var(--textThemeColor)"
                                      stroke-width="0.4"/>
                            </g>
                        </svg>
                    </div>
                    <div data-v-2381049e="" class="bread-crumbs" style="padding-right: 15px; cursor: pointer;color:var(--textThemeColor) !important">{{this.project !== null ? this.project.title : ''}} </div>
                </div>
                <div class="title__row mb-3">
                    <div class="d-flex top-video-inner align-items-center">
                        <span class="d-flex mr-auto">
                            <h1 class="project__title">My Videos</h1>
                            <div>
                                <div class="project__video__count">{{ totalVideos }}</div>
                            </div>
                        </span>
                        <div class="search-button-div" style="display: inline-flex">
                            <div style="margin-right: 10px" v-if="powerVideos.length > 0 || totalVideos > 0">
                                <SearchQuery :tip="''" @query='filter'></SearchQuery>
                            </div>
                            <div>
                            <!-- <div v-if="addVideo"> -->
                                <el-button v-if="!user.subuser || userData.settings.subuser_total_videos_allowed == 'unlimited' || 
                                +userData.settings.subuser_total_videos_allowed > allVideosCount" type="custom" 
                                class="button__upload" round @click="addPlayerDilogue = true">
                                    Add New Video
                                </el-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!--                        <div class="content__body" >-->
            <!--                            <div class="page__content">-->
            <!--                                <div class="video-no-list">-->
            <!--                                    <el-row type="flex">-->
            <!--                                        <el-col class="text-center">-->
            <!--                                            <el-row>-->
            <!--                                                <img class="no-project-img" src="../../assets/img/Image 256.svg" alt="">-->
            <!--                                            </el-row>-->
            <!--                                            <el-row>-->
            <!--                                                <h2 class="no-list-main-text">You don’t have any Video</h2>-->
            <!--                                                <div class='no-project-text pad-hor-15'>-->
            <!--                                                    <span>Use PowerPlayer to play your videos hosted on</span>-->
            <!--                                                    <span>3rd party platforms such as YouTube, Amazon s3 etc.</span>-->
            <!--                                                </div>-->
            <!--                                            </el-row>-->

            <!--                                        </el-col>-->
            <!--                                    </el-row>-->
            <!--                                </div>-->
            <!--                            </div>-->

            <!--                        </div>-->
            <div class="content__body" v-if="powerVideos.length == 0 && totalVideos == 0" v-loading="loading">
                <div class="page__content">
                    <div class="video-no-list">
                        <el-row type="flex">
                            <el-col class="text-center">
                                <el-row>
                                    <img class="no-project-img" src="../../assets/img/Image 256.svg" alt="">
                                </el-row>
                                <el-row>
                                    <h2 class="no-list-main-text">You don’t have any Video</h2>
                                    <div class='no-project-text pad-hor-15'>
                                        <span>Use PowerPlayer to play your videos hosted on</span>
                                        <span>3rd party platforms such as YouTube, Amazon s3 etc.</span>
                                    </div>
                                </el-row>

                            </el-col>
                        </el-row>
                    </div>
                </div>

            </div>
            <div class="page__content" v-else-if="powerVideos.length == 0 && totalVideos > 0" v-loading="loading">
                <span class="noVideo">No videos found</span>
            </div>
            <div class="page__content" v-else v-loading="loading">
                <list :powerVideos="powerVideos" @getPowerVideos="getPowerVideos"></list>
                <pagination @getVideos="getPowerVideos" :pagination="paginationData"></pagination>
            </div>

        </div>


        <el-dialog
                title="ADD NEW VIDEO"
                center
                class="el-dialog--huge dialog_edit dialog_border_radius add-power-player-dilogue"
                :visible.sync="addPlayerDilogue"
                :modalAppendToBody="false"
        >


            <div class="relative mb-3 ">
                <el-input v-model="newVideo.title" placeholder="VIDEO TITLE"></el-input>
                <span v-if="validateVideo.title" class="error-msg">{{validateVideo.message}}</span>

                <div style="margin-top: 20px;">
                    <el-input placeholder="VIDEO URL" v-model="newVideo.path" class="input-with-select">
                        <el-select v-model="newVideo.power_player_type" slot="prepend" @change="clearYTControl"
                                   :placeholder="newVideo.power_player_type" :style="{'width': 150+'px'}">
                            <el-option label="YOUTUBE" value="YOUTUBE"></el-option>
                            <el-option label="MP4" value="MP4/WEBM"></el-option>
                            <el-option label="WISTIA" value="WISTIA"></el-option>
                            <el-option label="VIMEO" value="VIMEO"></el-option>
                            <el-option label="M3U8" value="M3U8/MPD"></el-option>
                            <el-option label="LIVE" value="LIVE STREAMING"></el-option>
                        </el-select>
                        <!--                                        <el-button slot="append" icon="el-icon-search"></el-button>-->
                    </el-input>
                    <span v-if="validateVideo.path" class="error-msg">{{validateVideo.message}}</span>
                    <span v-if='validationError' class='error-msg'>{{ validationError }}</span>
                </div>
            </div>
            <div v-if="newVideo.power_player_type === 'YOUTUBE'">
                <el-col class="row-space-3">
                    <el-checkbox v-model="newVideo.youtube_control">
                        <span class="text-lightest" style="color: #FFF">Display YouTube logo for compliance</span>
                    </el-checkbox>
                </el-col>
            </div>

            <div slot="footer" class="dialog-footer">
                <el-button class="btn btn-primary add-power-player-button" :disabled="disabled"
                           @click="powerPlayerVideosStore">ADD VIDEO
                </el-button>
            </div>
        </el-dialog>

    </div>
</template>

<script>

    import List from '../PowerPlayer/Components/List'
    import SearchQuery from '../../components/Project/AllProjects/Components/SearchQuery.vue'
    import Pagination from '../../components/Common/Pagination.vue';

    export default {
        name: 'PowerPlayerDefault',
        props: {},
        components: {
            List,
            SearchQuery,
            Pagination
        },
        data: function () {
            return {
                validationError: false,
                loading: true,
                videoflag: false,
                powerVideos: [],
                totalVideos: 0,
                addPlayerDilogue: false,
                newVideo: {
                    title: '',
                    power_player_type: 'YOUTUBE',
                    path: '',
                    youtube_control: false,
                    project_id: null
                },
                validateVideo: {
                    title: false,
                    power_player_type: false,
                    path: false,
                    message: ''
                },
                paginationData: {
                    view: 10,
                    page: 1,
                    total: 0,
                    start: null,
                    end: null
                },
                disabled: false,
                user: null,
                userData: null,
                allVideosCount: 0,
                addVideo: false,
                project: null
            }
        },
        created: function () {
            this.getPowerVideos();
            this.user = this.$store.getters.userInfo;
            this.userData = JSON.parse(localStorage.getItem('user_info'));

            console.log(this.$route.params.id, this.$route.name, '***');

        },
        methods: {
            clearYTControl() {
                this.newVideo.youtube_control = false;
            },
            checklimit() {
                if (this.user.plan_video_limit === null || this.user.plan_video_limit === 'null') {
                    this.addVideo = true;
                } else if (this.user.plan_video_limit === 0) {
                    this.addVideo = false;
                } else if (this.user.plan_video_limit > 0 && this.totalVideos < this.user.plan_video_limit) {
                    this.addVideo = true;
                } else {
                    this.addVideo = false;
                }


                if(this.user.parent_id !== null){
 this.addVideo = true;
                }
            },
            filter(keywords) {
                this.getPowerVideos(keywords)
            },

            sendAxios(){

                if(typeof this.$route.params.id !== 'undefined'){
                    this.newVideo.project_id = this.$route.params.id;
                }

                    
                    if(this.newVideo.path.includes('youtu.be')){
                        var text = this.newVideo.path;
                        var parts = text.split("/");
                        this.newVideo.path = 'https://www.youtube.com/watch?v='+parts[3];
                    // console.log(this.newVideo.path.includes('youtu.be'), this.newVideo, parts);
                    }


                this.axios.post('/powerPlayerVideos/store', this.newVideo)
                .then(response => {
                    this.newVideo.title = '';
                    this.newVideo.power_player_type = 'YOUTUBE';
                    this.newVideo.path = '';
                    this.newVideo.youtube_control=true;
                    this.addPlayerDilogue = false;
                    this.disabled = false;
                    this.getPowerVideos();
                });
            },
            getPowerVideos(keywords = '') {

var project_id = 0;
                if(typeof this.$route.params.id !== 'undefined'){
                    project_id = this.$route.params.id;
                }

                if (keywords !== '') {
                    var url = `/powerPlayerVideos?project_id=${project_id}&keywords=${keywords}&page=${this.paginationData.page}&view=${this.paginationData.view}`;
                } else {
                    var url = `/powerPlayerVideos?project_id=${project_id}&page=${this.paginationData.page}&view=${this.paginationData.view}`;
                }

                this.axios.get(url)
                    .then(response => {
                        this.powerVideos = response.data.videos;
                        this.allVideosCount = response.data.allVideosCount;
                        this.paginationData.total = response.data.total;
                        this.paginationData.start = (this.paginationData.page - 1) * (this.paginationData.view) + 1;
                        this.paginationData.end = Math.min(this.paginationData.total, (this.paginationData.page - 1) * (this.paginationData.view) + this.paginationData.view);
                        this.totalVideos = response.data.total;
                        this.project = response.data.project;
                        this.checklimit();
                        this.loading = false;
                    })
                    .catch(error => {
                        this.loading = false;
                        this.$message.error(error.response.status == 403 || error.response.status == 404 ? error.response.data.message : 'Error! Something went wrong');
                        if(error.response.status === 401) {
                            this.$store.state.userData = null;
                            this.$store.dispatch("destroyToken").then(response => {
                                window.location.replace("/login/1");
                            });
                        } else {
                            window.location.replace('/projects');
                        }
                    });
            },
            powerPlayerVideosStore: function () {
                this.validateVideo.title = false;
                this.validateVideo.message = '';
                this.validateVideo.path = false;
                if (this.newVideo.title == '') {
                    this.validateVideo.title = true;
                    this.validateVideo.message = 'Please enter video title';
                    return false;
                } else {
                    this.validateVideo.title = false;
                    this.validateVideo.message = '';
                }
                if (this.newVideo.path == '') {
                    this.validateVideo.path = true;
                    this.validateVideo.message = 'Please enter source URL';
                    return false;
                } else {

// console.log(this.newVideo.power_player_type, 'this.newVideo.power_player_type');
                    if (this.newVideo.power_player_type == 'YOUTUBE') {
                        this.validateVideo.path = true;
                        this.validateVideo.message = 'Please enter valid source URL';
                        if (!this.newVideo.path.includes('www.youtube.com') && !this.newVideo.path.includes('youtu.be')) {
                            return false;
                        }
                    }
                    if (this.newVideo.power_player_type == 'WISTIA') {
                        this.validateVideo.path = true;
                        this.validateVideo.message = 'Please enter valid source URL';
                        if (!this.newVideo.path.includes('wistia.com')) {
                            return false;
                        }
                    }
                    if (this.newVideo.power_player_type == 'VIMEO') {
                        this.validateVideo.path = true;
                        this.validateVideo.message = 'Please enter valid source URL';
                        if (!this.newVideo.path.includes('vimeo.com')) {
                            return false;
                        }

                    }
                    if (this.newVideo.power_player_type == 'MP4/WEBM') {

                        var re = /(?:\.([^.]+))?$/;

                        var ext = re.exec(this.newVideo.path)[1];

                        if (typeof ext == 'undefined' || ext !== "mp4") {
                            this.validateVideo.path = true;
                            this.validateVideo.message = 'Please enter valid source URL';
                            if (!this.newVideo.path.includes('vimeo.com')) {
                                return false;
                            }
                        }
                    }
                    if (this.newVideo.power_player_type == 'M3U8/MPD') {

                        var re = /(?:\.([^.]+))?$/;

                        var ext = re.exec(this.newVideo.path)[1];

                        if (typeof ext == 'undefined' || ext !== "m3u8") {
                            this.validateVideo.path = true;
                            this.validateVideo.message = 'Please enter valid source URL';
                            if (!this.newVideo.path.includes('vimeo.com')) {
                                return false;
                            }
                        }
                    }

                    this.validateVideo.path = false;
                    this.validateVideo.message = '';
                }

                this.newVideo.path = decodeURIComponent(this.newVideo.path);

                this.disabled = true;
                if(this.newVideo.power_player_type == 'YOUTUBE'){

                     if(this.newVideo.path.includes('youtu.be')){
                        var text = this.newVideo.path;
                        var parts = text.split("/");
                        this.newVideo.path = 'https://www.youtube.com/watch?v='+parts[3];
                    // console.log(this.newVideo.path.includes('youtu.be'), this.newVideo, parts);
                    }
                    this.axios.post('/youtubeVideoStatus', this.newVideo)
                        .then(response => {
                            if (response.data.status){
                                this.sendAxios();
                            }else{
                                this.validationTrigger(response.data.message, false);
                                this.disabled = false;
                            }
                        }).catch(error => {
                        this.validationTrigger('ERROR: The owner of this content has blocked embedding outside YouTube. If you\'re the owner, change this in your video settings on Youtube', false);
                        this.disabled = false;
                    });
                }else{
                    this.sendAxios();
                }


                // if(this.videoflag == true){
                //     this.axios.post('/powerPlayerVideos/store', this.newVideo)
                //         .then(response => {
                //             this.newVideo.title = '';
                //             this.newVideo.power_player_type = 'YOUTUBE';
                //             this.newVideo.path = '';
                //
                //             this.addPlayerDilogue = false;
                //             this.disabled = false;
                //             this.getPowerVideos();
                //         });
                // }
            },

            validationTrigger: function(message, timeout = 3000)
            {
                if (!this.validationError)
                    this.validationError = message;
                if (timeout) {
                    setTimeout(() => {
                        if (this.validationError) this.validationError = false;
                    }, timeout);
                }
            },
        },
    }
</script>
<style lang="less">

.link__projects{
        margin: 0 6.4px 0 -15px;
    padding: 0 15px;
    color: var(--textThemeColor) !important;
    text-decoration: none;
    border-radius: 15px;
}
.header__icon{
    margin-right: 20px;
    color: var(--textThemeColor) !important;
}

    @import "./../../assets/less/custom/fonts";

    .el-scrollbar__wrap {
        min-width: 160px;
    }

    .el-select-dropdown__list {
        padding: 0 !important;
    }

    .top-video-inner {
        @media (max-width: 550px) {
            display: block !important;
        }

        .search-button-div {
            @media (max-width: 550px) {
                display: inline-flex;
                width: 100%;
                justify-content: flex-end;
                margin-top: 10px;
            }
        }
    }

    .no-project-img {
        max-width: 100%;
    }

    .power-player-page {
        /*background-color: var(--color-blue-light);*/
        background-color: var(--backgroundThemeColor);
        font-family: "Helvetica Neue";
        font-weight: normal;
        font-style: normal;
        color: var(--color-content);
        padding: 68px 25px 20px;
        min-height: calc(~'100vh - 76px');
        box-sizing: border-box;
        @media (max-width: 480px) {
            padding: 10px 10px 20px !important;
        }

        .noVideo {
            display: block;
            text-align: center;
            padding: 25px;
        }

        .power-player-dashboard {
            margin: auto 500px;

            .no-list-main-text {
                font-size: 18px;
                font-weight: 500;
            }

            .no-project-text {
                font-size: 13px;
                display: grid;
            }

            .toolbar__search {
                /*margin-right: 31px;*/

                .search__icon {
                    box-sizing: border-box;
                }

                .filter__search {
                    input.el-input__inner {
                        height: 32px;
                        font-size: 13px;
                    }

                    &:focus-within {

                    }
                }
            }

            .filter__search {
                display: flex;
                align-items: center;
                border: 2px solid #ffffff;
                /*border: 1px solid #0a0a0a;*/
                border-radius: 16px;
                overflow: hidden;
                cursor: pointer;

                .el-input-group__prepend {
                    display: block;
                    width: auto;
                    background-color: transparent;
                    line-height: 0;
                    border: 0;
                    padding-right: 8px;
                    padding-left: 8px;

                    svg {
                        width: 16px;
                    }
                }

                input.el-input__inner {
                    box-sizing: border-box;
                    width: 0;
                    height: 32px;
                    padding-left: 0;
                    padding-right: 0;
                    font-size: 13px;
                    border: 0;
                    transition: all .3s ease-out;
                }

                svg {
                    path {
                        fill: #0a0a0a;
                    }
                }

                &:focus-within, &:hover {
                    border-color: #0a0a0a;

                    .el-input-group__prepend {
                        svg {
                            path {
                                fill: #0a0a0a;
                            }
                        }
                    }

                    input.el-input__inner {
                        width: 180px;
                        padding-right: 10px;
                    }

                    @media (max-width: 960px) {
                        input.el-input__inner {
                            width: 100px;
                        }
                    }
                }
            }

            /*            .filter__search input.el-input__inner{*/
            /*display: none;*/
            /*            }*/
            @media (max-width: 1700px) {
                margin: auto 300px !important;
            }
            @media (max-width: 1450px) {
                margin: auto 200px !important;
            }
            @media (max-width: 1200px) {
                margin: auto 100px !important;
                .player_video__body .list-power-video .power-player-detail .item__path {
                    max-width: 200px;

                }


                .player_video__body .list-power-video .power-player-detail .item__title {

                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    max-width: 100px;
                }
            }
            @media (max-width: 850px) {
                margin: auto 5px !important;
            }
        }

        .add-power-player-dilogue.dialog_edit .el-dialog__title {
            text-transform: uppercase;
            letter-spacing: 1px;
            font-weight: normal;
            font-size: 15px;
            color: #fff;
        }

        .add-power-player-dilogue {
            .el-dialog__headerbtn {
                font-size: 22px;
                position: absolute;
                right: 20px;
                top: 20px;
            }

            el-input-group__prepend {
                border-radius: 9px;
            }

            .el-dialog {
                max-width: 600px;
                width: 90%;
            }

            .error-msg {
                color: white;
            }

            .el-dialog__body {
                padding: 30px 30px 0px;
            }

            .el-dialog--center .el-dialog__header {
                padding: 25px 40px;
            }

            .el-dialog__body {
                padding: 8px 50px !important;
            }

            .dialog_edit .dialog-footer {
                margin: 0 !important;
            }

            .el-dialog__footer {
                padding: 10px 30px 20px;

                .dialog-footer {
                    text-align: right !important;
                    width: 100% !important;
                    display: block !important;
                    padding: 0 30px 0px !important;
                }

                button {
                    color: #fff !important;
                    font-size: 17px !important;
                    padding: 8px 25px !important;
                    line-height: 1.42857143;
                }
            }

            .el-dialog--center .el-dialog__header {
                text-align: center;
                border-bottom: 1px solid #e4e8f1;
            }

            .el-dialog {
                background: var(--backgroundThemeColor);
                padding: 15px;

                .el-dialog__header {
                    border-bottom: 2px solid #0a566d;
                }

                .el-dialog__title {
                    font-size: 20px;
                }
            }

            .el-select input {
                background: #233d53 !important;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                color: #fff !important;
                /*margin-left: -1px;*/
                border-top-left-radius: 6px;
                border-bottom-left-radius: 6px;
            }

            .el-input-group__prepend {
                border-radius: 9px;

                .el-select {
                    margin: -11px;
                }
            }

            .el-select:hover {
                background: #233d53;
                margin-left: -11px;
                border-top-left-radius: 6px;
                border-bottom-left-radius: 6px;
            }

            .el-input-group__append, .el-input-group__prepend, .el-select .el-input .el-input__icon {
                color: #fff;
            }

            .el-select .el-input .el-input__icon {
                font-size: 18px;
                font-weight: 700;
            }

            .add-power-player-button {
                border-radius: 5px;
                background-color: #F47000;
                border-color: #F47000;
                font-size: 17px;
                font-weight: normal;
            }

            .el-input__inner {
                height: 50px;
                font-size: 15px;
            }

        }

        .button__upload {
            padding: 10px 25px 9px;
            background-color: var(--buttonThemeColor);
            border: none;
            font-size: 16px;
            line-height: 19px;
            font-weight: 500;
            color: white;
            border-radius: 5px;
            position: relative;
            overflow: hidden;

            .upload__input {
                position: absolute;
                top: -100px;
                left: -200px;
                width: 500px;
                height: 300px;
                opacity: 0;
                cursor: pointer;
            }

        }

        .hero__content {
            .project__title {
                margin-bottom: 0;
                font-size: 24px;
                line-height: 28px;
                /*color: var(--color-content);*/
                color: var(--textThemeColor);
                margin-right: 23px;
                position: relative;
                font-weight: 700 !important;
                margin-top: 0;
            }

            .project__video__count {
                font-size: 24px;
                line-height: 28px;
                /*color: var(--color-content);*/
                color: var(--textThemeColor);
                opacity: .7;
            }
        }

        .content__body {
            background-color: #eff8fa;
            padding: 30px 30px 30px;
            box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, .04);
            border-radius: 12px;
            border: 1px solid #8ad6ec;

        }
    }

    @media (max-width: 960px) {
        .power-player-page {
            padding: 10px 25px 20px;

        }
    }

</style>
